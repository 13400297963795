<template>
    <div>
        <ValidationObserver ref="observer">
            <app-input v-model="cEmail" 
                       validatorName="Email" 
                       :validatorRules="checked_email_key != '' ? (validatorRules ? validatorRules+'|email':'email'):''"  
                       :loading="loading_email_check" 
                       :label="label" 
                       :additionalInfo="additionalInfo" 
                       v-on:blur="checkEmailExist()"
                       v-on:change="change"
                       @input="input"
                       :customSpinnerClass="customSpinnerClass"
            />
            
            <app-input type="hidden"  v-model="checked_email_key" name="email" validatorRules="required" :validatorCustomMessage="{ required: trans('email-exist-error',191) }"/>
        </ValidationObserver>
    </div>
</template>
<script>


import axios from "@axios";
import {ValidationObserver} from "vee-validate";

export default {

    components: {
        ValidationObserver
    },

    props: {
            email: { type: String },
            type: { type: String },
            changeMode:{ type:Boolean, default: false },
            validatorRules: { type: String },
            label: {type: String},
            additionalInfo: {type: String},
            customSpinnerClass:{ type: String, default: "align-middle app-input-spinner"},
            
    },

    created() {
        this.cEmail = this.email;
    },

    computed: {
        
        /*cEmail: {
            get() { return this.email },
            set(value) {
                this.$emit('update:email', value);
                    this.clearError(value) ;
            }
        },*/
    },

    data() {
        return {
            cEmail: null,
            last_email_checked: '',
            core_email: this.email,
            loading_email_check: false,
            checked_email_key: 'email',
            valid_last_email: true,
            email_regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        };
    },

    methods: {

         async checkEmailExist(){
            
            if(this.last_email_checked != this.cEmail && (!this.changeMode || this.core_email != this.cEmail) && this.cEmail != null && this.cEmail.match(this.email_regex)){
                this.loading_email_check = true;
                this.errorMessage={email: ''};
                await axios
                    .post("core/email/exist", {
                        type: this.type,
                        email: this.cEmail,
                    })
                    .then((res) => {
                        if(res.data == 1){
                            this.checked_email_key = ''; //pusty string trigeruje regułę requierd na ukrytym inpucie
                            this.valid_last_email = false;
                            
                        }else{
                            this.checked_email_key = 'email';
                            this.valid_last_email = true;
                        }
                        this.last_email_checked = this.cEmail;
                        this.$emit('checked');
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading_email_check = false;
                });
                this.$refs.observer.validate();
            }
        },
        
        clearError(email){
            this.checked_email_key = 'email';
            if(this.last_email_checked == email && !this.valid_last_email){
                this.checked_email_key = '';
            } 
            this.$refs.observer.validate();
        },

        change(){
            this.$emit('update:email', this.cEmail);
            this.clearError(this.cEmail) ;
        },

        input(){
            this.$emit('input', this.cEmail);
        }
    }

    };
</script>

<style>

</style>