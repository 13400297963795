<template>
    <div style="min-height:200px"> 
        
    <b-row> 
        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">
            <ValidationObserver ref="form">
            <b-card class="mb-3">
                    
                <b-row>
                    <b-col start>
                        <div class="app-card-profile-title mb-3">{{trans('settings-system-variables',207)}}</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6" xl="6">

                        <app-row-left-label>
                            <app-input id="vi-email" :label="trans('settings-topdesk-email',207)" v-model="topdesk_email" validatorRules="required|multiple-emails" validatorName="Email" size="sm" :disabled="loading_email_check == 1"/>
                        </app-row-left-label>

                        <app-row-left-label>
                            <app-email :email.sync="debtors_email" validatorRules="required" :label="trans('settings-debtors-email',207)" :key="refresh" class="app-local-settings-email" ref="email_control"/>
                        </app-row-left-label>

                        <app-row-left-label>
                            <app-date v-model="last_day_calculation_of_year" :label="trans('last-day-calculation-of-year',260)" :validatorName="trans('last-day-calculation-of-year',260)" validatorRules="required|date-check|date-more-check" />
                        </app-row-left-label>

                    </b-col>
                </b-row>
            </b-card>
            <app-button type="primary" @click="change" :loading="loading" >
                        {{trans('save',175)}}
            </app-button>
            </ValidationObserver>
        </b-col>
    </b-row>

                
    </div>
</template>

<script>
import axios from "@axios";
import {  ValidationObserver } from "vee-validate";
import AppEmail from '@core/components/AppEmail.vue';

export default {
    
    components: {
        ValidationObserver,
        AppEmail    
    },

    created(){
        this.getData();
    },

    data(){
        return{
            account_manager: {name: "", value: null},
            loading: false,
            topdesk_email: null,
            loading_email_check: 0,
            last_day_calculation_of_year: null,
            debtors_email: null,
            refresh: 0
        }
    },

    methods: {

        getData: function() {
            this.loading = true;
            
            axios
                .post("settings/variables/getData")
                .then((res) => {
                    this.fillFields(res.data);
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`)
                });
            
        },

        fillFields: function(data){

            var topdesk_emailIndex = data.indexOf(data.find(item => item.field_name == 'topdesk_mail'));
            this.topdesk_email = data[topdesk_emailIndex].field_value;

            var debtors_emailIndex = data.indexOf(data.find(item => item.field_name == 'debtors_mail'));
            this.debtors_email = data[debtors_emailIndex].field_value;

            var last_day_calculation_of_year = data.indexOf(data.find(item => item.field_name == 'last_day_calculation_of_year'));
            this.last_day_calculation_of_year = data[last_day_calculation_of_year].field_value;

            this.refresh ++;
        },

        change() {
            
            this.$refs.form.validate().then((result) =>{
                if(!result){
                    return false;
                }
                else{
                    if(this.$refs.email_control.loading_email_check == false){

                        this.loading = true;

                        axios
                            .post("settings/variables/change", [
                                {
                                    field_name: 'topdesk_mail',
                                    field_value: this.topdesk_email
                                },
                                {
                                    field_name: 'debtors_mail',
                                    field_value: this.debtors_email
                                },
                                {
                                    field_name: 'last_day_calculation_of_year',
                                    field_value: this.last_day_calculation_of_year
                                },
                            ])
                            .then((res) => {
                                this.loading = false;
                                this.fillFields(res.data);
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            });
                    }
                }
            });
        },
    }

}
</script>

<style>
.app-local-settings-email .app-input-spinner{
    top:33px !important
}
</style>